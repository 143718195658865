html{
    scroll-behavior: smooth;
}

.footer-logo {
    width: 100%;       /* Make the image take full width of container */
    height: 100%;
    border-radius: 50%;      /* Make the image take full height of container */
    object-fit: cover; /* Ensure the image covers the container without distortion */
}

